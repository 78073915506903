$(document).ready(initPage);

function initPage() {
  $(".topmenu a").click(function () {
    event.preventDefault();
    $(".toggler").toggleClass("expanded");
    $(".topmenu").toggleClass("expanded");
    $("html, body").animate(
      {
        scrollTop: $($.attr(this, "href")).offset().top
      },
      500
    );
  });

  $(".toggler").click(function () {
    $(this).toggleClass("expanded");
    $(".topmenu").toggleClass("expanded");
  });

  const thumbsSwiper = new Swiper(".thumbs-slider", {
    loop: false,
    spaceBetween: 10,
    slidesPerView: 4,
    watchSlidesVisibility: true
    // navigation: {
    //   nextEl: ".thumbs-slider-w .swiper-button-next",
    //   prevEl: ".thumbs-slider-w .swiper-button-prev"
    // }
  });
  const mainSwiper = new Swiper(".main-slider", {
    loop: false,
    spaceBetween: 10,
    simulateTouch: false,
    effect: "fade",
    navigation: {
      nextEl: ".thumbs-slider-w .swiper-button-next",
      prevEl: ".thumbs-slider-w .swiper-button-prev"
    },
    thumbs: {
      swiper: thumbsSwiper,
      autoScrollOffset: 1
    }
  });

  $.fn.BeerSlider = function (options) {
    options = options || {};
    return this.each(function () {
      new BeerSlider(this, options);
    });
  };
  $(".beer-slider").each(function (index, el) {
    $(el).BeerSlider({ start: $(el).data("start") });
  });

  isScrolled();

  $(window).scroll(function () {
    isScrolled();
  });

  function isScrolled() {
    let st = $("html, body").scrollTop();
    if (st > 0) {
      $("body").addClass("scrolled");
    } else {
      $("body").removeClass("scrolled");
    }
  }

  $(".section-faq .question").click(function () {
    $(this).parent().toggleClass("expanded");
  });
}
